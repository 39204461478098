import React from 'react';

interface Props { }

const LocationComponent: React.FC<Props> = () => {
	return (
		<div className="location-contact">
			<div className="container">
				<div className="inner">
					<div className="row">
						<div className="col-sm-6">
							<div className="location-info location-info-left d-flex">
								<h6>Hà Nội</h6>
								<p className='heigth-col'>
									Tầng 6, Tòa nhà 16-18, ngõ 71, Láng Hạ,<br />Thành Công, Ba Đình, Hà Nội, Việt Nam
								</p>
								<a className="cl1" rel="noreferrer" href="https://goo.gl/maps/35ehZL85TJtr3vNUA" target="_blank">Xem bản đồ</a>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="location-info d-flex">
								<h6>Hồ Chí Minh</h6>
								<p className='heigth-col'>
									Tầng 10, 198 Đường 3/2 P12 <br /> Q10, TP Hồ Chí Minh, VN
								</p>
								<a className="cl1" rel="noreferrer" href="https://goo.gl/maps/wUXQZwveg9Cp47KH9" target="_blank">Xem bản đồ</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LocationComponent;