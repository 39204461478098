import { notification } from 'antd';
import { isValidPhoneNumber } from 'libphonenumber-js';

class VarHelper {
	validatePhoneVn = (str = '') => {
		let phone = str.trim().replace(/[^0-9+\-]/g, '');
		if (
			phone.length < 10 ||
			phone.length > 12 ||
			(phone.length === 10 && phone[0] !== '0') ||
			(phone.length === 12 && phone.slice(0, 3) !== '+84') ||
			(phone.length === 11 && phone.slice(0, 2) !== '84') ||
			str.length !== phone.length
		) {
			return false;
		}
		if (phone[0] === '0') return `+84${phone.slice(1)}`;
		if (phone[0] === '8' && phone[1] === '4') return `+${phone}`;

		return phone;
	};

	isEmailValid = (email: string) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email.toLowerCase());
	};

	isPhoneValid = (phone: string) => {
		const re = /^\+?\(?([\d]{3,4})\)?[-.]?\(?([\d]{3})\)?[-.]?\(?([\d]{4})\)?$/;
		let _phone = this.validatePhoneVn(phone);
		return re.test(phone) && (isValidPhoneNumber(phone) || _phone);
	};

	notificationError = (message: string) => {
		return notification.error({
			message,
			placement: "topRight",
			top: 100,
		})
	}
}

export default new VarHelper();