import React from 'react';
// @ts-ignore
import OwlCarousel from 'react-owl-carousel2';
import { BANNERS } from 'src/constants';
import SlideItem from './SlideItem';

const banerOptions = {
	loop: true,
	margin: 0,
	autoplay: true,
	dots: true,
	rewind: true,
	nav: true,
	responsive: {
		0: {
			items: 1
		},
		1024: {
			items: 1
		}
	}
};

const Banner = () => {
	return (
		<OwlCarousel options={banerOptions}>
			{BANNERS.map((item, i) => {
				return (
					<SlideItem
						key={`banner${i + 1}`}
						title={item.title}
						description={item.description}
						button1_href={item.button1_href}
						button2_href={item.button2_href}
						button1_title={item.button1_title}
						button2_title={item.button2_title}
						image={item.image}
					/>
				)
			})}
		</OwlCarousel>

	);
};

export default Banner;