import { Link } from "react-router-dom";
interface Props {
    image: any,
    title?: string,
    description?: string,
    button1_title?: string,
    button2_title?: string,
    button1_href?: string,
    button2_href?: string,
}

const SlideItem = (props: Props) => {
    const scrollToSignUp = () => {
        const signUpElement = document.getElementById('sign-up-home');
        if (signUpElement) {
            const y = signUpElement.getBoundingClientRect().top - 100;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    return (
        <div className="container itemSlider">
            <div className="row">
                <div className="col-md-5 header-middle">
                    <div className="txt">
                        <h1>
                            {props.title}
                        </h1>
                        <p>
                            {props.description}
                        </p>

                        <div className="banner-hrm">
                            <Link to={'/register'} target={"_blank"} className="btnHeader_Fill color-trial">
                                Dùng thử miễn phí
                            </Link>
                            <div className="btnHeader_Fill ml-3" onClick={scrollToSignUp}>Nhận tư vấn</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <img alt={props.title} src={props.image} />
                </div>
            </div>
        </div>
    )
}

export default SlideItem;
