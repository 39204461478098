import { Helmet } from "react-helmet";
import * as React from 'react';

interface Props {
    title?: string,
    contentDescription?: string,
    contentKeywords?: string,
    link?: string,
}

const HelmetPage = (props: Props) => {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.contentDescription} />
            <meta name="keywords" content={props.contentKeywords} />
            <link rel="canonical" href={props.link} />
            <meta name="og:title" content="ACheckin" />
            <meta name="og:description" content="Đăng ký dùng thử miễn phí ACheckin quản lý nhân sự, quản lý dự án và quản lý tài nguyên Doanh nghiệp tại ACheckin.vn" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="" />
        </Helmet>
    )
}

export default HelmetPage;