import React from 'react';
// @ts-ignore
import OwlCarousel from 'react-owl-carousel2';
import { CUSTOMERS_PARTNER } from 'src/constants';

interface Props { }

const options = {
	nav: true,
	loop: true,
	margin: 0,
	autoplay: true,
	dots: true,
	rewind: true,
	items: 1,
};


const Partner: React.FC<Props> = () => {
	return (
		<div className="partner">
			<div className="container">
				<div className="title-block text-center">
					<span className="title">Khách hàng</span>
					<div style={{ fontSize: 18 }}>3500++ doanh nghiệp đã đăng ký</div>
					<span className="line" />
				</div>

				<OwlCarousel options={options}>
					{CUSTOMERS_PARTNER.map((item, i) => {
						return (
							<div key={`list_${i + 1}`}>
								{item.images.map((d, ind) => (
									<div className='row justify-content-center col-margin-left' key={`item_${ind + 1}`}>
										{d.data.map((dItem, kItem) => (
											<div className='col-2' key={`kItem_${kItem + 1}`}>
												<img src={dItem.img} className='image-partner' />
											</div>
										))}
									</div>
								))}
							</div>
						)
					})}
				</OwlCarousel>
			</div>
		</div>
	);
};

export default Partner;