import classNames from 'classnames';
//@ts-ignore
import OwlCarousel from 'react-owl-carousel2';
//@ts-ignore
import $ from 'jquery';
import React, { useEffect } from 'react';

export interface IFeatureSlide {
    title: string,
    items: any[],
    image: any
}

interface Props {
    ref?: any,
    id: string,
    title: string,
    index: number,
    features: IFeatureSlide[],
}

const FeatureSlide = (props: Props) => {
    const features = props.features || [];
    const sectionClassName = props.index % 2 ? 'section1' : 'section';

    useEffect(() => {
        setupListener();
    }, []);

    const setupListener = () => {
        for (let i in features) {
            $(`.dots-container-tab-${props.id} .${sectionClassName}:eq(${i})`).hover(function () {
                let max = features.length;
                for (let j = 0; j < max; j++) {
                    $(`.dots-container-tab-${props.id} .${sectionClassName}:eq(${j})`).removeClass("active");
                }
                $(`.block-feature-${props.index + 1} .owl-dots .owl-dot:eq(${i})`).click();
                // @ts-ignore
                $(this).addClass('active');
            });
        }
    }

    return (
        <div className={classNames('block-feature', 'pt-md-70', 'pb-md-70', {
            'block-Gray': props.index % 2 === 1,
            [`block-feature-${props.index + 1}`]: true
        })}>
            <div className="container">
                <div className="inner action padding-top" ref={props.ref}>
                    <div className="title-block text-center">
                        <a href={`#${props.id}`} className="navigation__link active"><h2 className="title page-section" id={props.id}>{props.title}</h2></a>
                        <span className="line" />
                    </div>
                    <div className="row mb-5 mb-md-100">
                        <div className="col-md-8">
                            <div className={classNames('d-flex flex-column justify-content-center h-100', { [`dots-container-tab-${props.id}`]: true })}>
                                {features.map((feature, i) => {
                                    return (
                                        <div key={`feature${props.id}${i}`} className={`${sectionClassName} mb-md-50`}>
                                            <div className="title-feature-page">
                                                {feature.title}
                                            </div>
                                            <div className="txt-block-feature">
                                                {feature.items.map((item, j) => {
                                                    return (
                                                        <p key={`item${props.id}${i}${j}`}>
                                                            <img alt="ACheckin - Quản lý nhân sự, chấm công thông minh bằng QR code" title="ACheckin - Quản lý nhân sự, chấm công thông minh bằng QR code" src={require('src/assets/images/feature/dot.png')} height={12} width={12} className="mr-2" />
                                                            {item}
                                                        </p>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="slideFeature">
                                <div className="iphone">
                                    <img alt="ACheckin - Quản lý nhân sự, chấm công thông minh bằng QR code" title="ACheckin - Quản lý nhân sự, chấm công thông minh bằng QR code" src={require('src/assets/hrm/feature_slides/phone-bg.png')} />
                                </div>
                                <div className="innerslide">
                                    <OwlCarousel options={options}>
                                        {features.map((item, i) => (
                                            <div className="item " key={`phone${props.id}${i}`}>
                                                <a href="javascript:;" >
                                                    <img alt="ACheckin: App chấm công và quản lý tài nguyên Doanh Nghiệp" src={item.image} />
                                                </a>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(FeatureSlide);

const options = {
    loop: true,
    margin: 0,
    autoplay: true,
    dots: true,
    rewind: true,
    responsive: {
        0: {
            items: 1
        },
    }
};