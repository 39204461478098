import React from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import HomeScreen from './screens/Home/HomeScreen';
import FeatureScreen from './screens/Feature/FeatureScreen';
import DownloadScreen from './screens/Download/DownloadScreen';
import ContactScreen from './screens/Contact/ContactScreen';
import RedirectScreen from './screens/Register/RedirectScreen';
import HrmScreen from './screens/Hrm/HrmScreen';

function App() {
  return (
    <Switch>
      <Route path="/feature" component={FeatureScreen} />
      <Route path="/download" component={DownloadScreen} />
      <Route path="/contact" component={ContactScreen} />
      <Route path="/register" component={RedirectScreen} />
      <Route path="/hrm" component={HrmScreen} />
      <Route path="/" component={HomeScreen} />
    </Switch>
  );
}

export default App;
