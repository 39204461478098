import React, { useState } from 'react';
import { Modal, Form, Input, Button, notification } from 'antd';
import VarHelper from 'src/utils/VarHelper';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
interface Props {
}
export interface IFormDataContact {
    fullname: string
    email: string
    phone_number: string
    company?: string
    position_in_company?: string
    company_size?: string
    captcha_token: string
}

const ModalHome: React.FC<Props> = (props) => {
    const recaptchaRef = React.useRef()
    const [visible, setVisible] = useState<boolean>(true)
    const [form] = Form.useForm()

    const onFinish = async (values: IFormDataContact) => {
        if (!values.fullname) return VarHelper.notificationError("Vui lòng nhập Họ và Tên")
        if (!values.email || !VarHelper.isEmailValid(values.email)) {
            return VarHelper.notificationError("Email không hợp lệ")
        }
        if (!values.phone_number || !VarHelper.isPhoneValid(values.phone_number)) {
            return VarHelper.notificationError("Số điện thoại không hợp lệ")
        }

        // @ts-ignore
        const captcha_token = await recaptchaRef.current.executeAsync()
        values.captcha_token = captcha_token

        fetch('https://api-inside.dev.acheckin.vn/v1/landing/users/request-consulting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then(() => {
            setVisible(false)
            notification.success({
                message: "Đăng ký thành công. Chúng tôi sẽ liên hệ với bạn sớm nhất!",
                placement: "topRight",
                top: 100,
            })
        }).catch(() => {
            setVisible(false)
        })
        // @ts-ignore
        recaptchaRef.current?.execute()
    }

    return (
        <Modal
            width={450} title={false} footer={false}
            visible={visible} className="custom-modal-home"
            closeIcon={<img src={require('src/assets/hrm/close_modal.png')} width="30px" />}
            onCancel={() => setVisible(false)}
        >
            <div>
                <div>
                    <img src={require('src/assets/images/popup_banner.png')} alt="modal-home" className='img-modal-home' />
                </div>
                <div className='modal-padding'>
                    <div className='title-modal-home' style={{ fontWeight: 600, fontSize: 22 }}>
                        Miễn phí <span style={{ fontWeight: 400, fontSize: 22 }}>sử dụng</span> 3 tháng<br /> khi đăng ký ngay hôm nay.
                    </div>
                    <Form form={form} onFinish={onFinish}>
                        <Form.Item name="fullname">
                            <Input placeholder='Họ và tên*' className='input-antd input-antd-modal' />
                        </Form.Item>

                        <Form.Item name="email">
                            <Input placeholder='Email*' className='input-antd input-antd-modal' />
                        </Form.Item>

                        <Form.Item name="phone_number">
                            <Input placeholder='Số điện thoại*' className='input-antd input-antd-modal' />
                        </Form.Item>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6Le-0a0fAAAAALflrsxsttAnJyKyKtAuC_Dc2rT2"
                        />
                        <div className='text-center'>
                            <Button htmlType='submit' className='button-registration'>
                                ĐĂNG KÝ
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default ModalHome;