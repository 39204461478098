import 'src/assets/bootstrap/css/bootstrap.min.css';
import { Footer, Header, Helmet } from 'src/components';
import FreeConsultation from '../Home/components/FreeConsultation';
import Partner from '../Home/components/Partner';
import FeatureSlide from './components/FeatureSlide';
import FeatureStatic, { IFeatureStaticProps } from './components/FeatureStatic';
import SlideItem from './components/SlideItem';
import './styles.css';
import React from 'react'

const WHY_USE = [
	{ title: 'Đa dạng hình thức chấm công', description: 'Linh hoạt lựa chọn hình thức chấm công phù hợp với từng phòng ban trong doanh nghiệp: camera AI, QR code, kết nối wifi, định vị. ', image: require('src/assets/hrm/hrm_why_use_1.png') },
	{ title: 'Tự động quản lý tiền lương', description: 'Thêm bảng lương tùy chỉnh cho từng cá nhân, bộ phận, phòng ban. Thiết lập công thức tính lương tự động, phân quyền quản trị thang bảng lương nhân sự. ', image: require('src/assets/hrm/hrm_why_use_2.png') },
	{ title: 'Số hóa dữ liệu nhân sự', description: 'Chuẩn hóa và quản lý thông tin nhân sự khoa học, dễ dàng khai thác dữ liệu hỗ trợ công tác quản trị nhân lực trong doanh nghiệp.', image: require('src/assets/hrm/hrm_why_use_3.png') },
]

const FEATURES_SLIDE_1 = [
	{ title: 'Thiết lập cấu hình tính lương theo hệ thống tiền lương của doanh nghiệp', image: require('src/assets/hrm/hrm_feature_slide_1.png') },
	{ title: 'Tự động cập nhật dữ liệu chấm công, đơn báo nghỉ vào bảng lương tháng theo cấu hình đã được thiết lập', image: require('src/assets/hrm/hrm_feature_slide_2.png') },
	{ title: 'Xuất phiếu lương và gửi phiếu lương tự động cho nhân sự qua ứng dụng', image: require('src/assets/hrm/hrm_feature_slide_3.png') },
	{ title: 'Quản lý và theo dõi lương của từng nhân sự, bộ phận phòng ban', image: require('src/assets/hrm/hrm_feature_slide_4.png') },
]

const FEATURES_SLIDE_2 = [
	{ title: 'Quản lý thông tin nhân sự: vị trí công việc, chức danh, phòng ban, giới tính, thông tin liên hệ, số tài khoản ngân hàng,...', image: require('src/assets/hrm/hrm_feature_slide_profile_1.png') },
	{ title: 'Lưu trữ và đồng bộ hồ sơ nhân sự (quản lý hợp đồng, các khoản truy lĩnh truy thu, tạm ứng, phụ cấp,...) trên 1 nền tảng duy nhất', image: require('src/assets/hrm/hrm_feature_slide_profile_2.png') },
	{ title: 'Ghi nhận thâm niên, thành tựu của nhân sự', image: require('src/assets/hrm/hrm_feature_slide_profile_3.png') },
]

const FEATURES_STATIC_1: IFeatureStaticProps = {
	title: 'Giúp nhà quản lý',
	image: require('src/assets/hrm/hrm_feature_slide_static_bg_1.png'),
	description: [
		'Tiết kiệm nguồn lực quản lý và xử lý hồ sơ nhân sự',
		'Thống kê và xuất báo cáo tiền lương nhanh chóng, đơn giản',
		'Bảo mật thông tin nhờ hệ thống phân quyền',
		'Đơn giản và chuẩn hoá quy trình quản trị',
		'Đa dạng hóa hình thức chấm công cho từng phòng ban'
	]
}

const FEATURES_STATIC_2: IFeatureStaticProps = {
	title: 'Giúp nhân viên',
	image: require('src/assets/hrm/hrm_feature_slide_static_bg_2.png'),
	description: [
		'Chủ động nắm bắt thời gian làm việc, ngày nghỉ và bảng lương cá nhân',
		'Tiết kiệm thời gian báo nghỉ, đổi ca, chấm công.',
		'Gắn kết với doanh nghiệp nhờ công cụ truyền thông nội bộ thú vị: lời chào, GameTeam công nghệ, radio nội bộ, workplace,...',
	]
}

const optionsFeature1 = {
	loop: true,
	margin: 0,
	autoplay: true,
	dots: true,
	rewind: true,
	dotsContainer: '.featurehot-v2',
	responsive: {
		0: {
			items: 1
		},
	}
};


const optionsFeature2 = {
	loop: true,
	margin: 0,
	autoplay: true,
	dots: true,
	rewind: true,
	dotsContainer: '.featurehot-v2-2',
	responsive: {
		0: {
			items: 1
		},
	}
};

const HrmScreen = () => {
	const recaptchaRef = React.useRef()

	return (
		<div className="home-v1">
			<Helmet
				title="ACheckin - Giải pháp quản trị nâng cao trải nghiệm nhân sự tốt nhất"
				contentDescription="ACheckin – Ứng dụng quản lý nhân sự, tài nguyên và dự án miễn phí đa nền tảng cho Doanh nghiệp: chấm công, xin nghỉ phép... trên iOS, Android, PC, Web/CMS"
				contentKeywords="phần mềm chấm công miễn phí, ứng dụng quản lý nhân sự, app chấm công, ứng dụng quản lý công việc, phần mềm quản lý dự án, quản lý hệ thống, quản lý tài nguyên"
				link="https://acheckin.vn"
			/>

			<header id="v2" className='hrm-min-height'>
				<Header />
				<div className="container padding-top-87">
					<SlideItem
						key={`banner_top`}
						title={'Quản lý nhân sự toàn diện với ACheckin HRM '}
						description={'Dễ dàng quản lý và theo dõi tiền lương của từng nhân sự, bộ phận phòng ban. Thiết lập cấu hình tính lương, thang bảng lương theo nhu cầu doanh nghiệp. '}
						image={require('src/assets/hrm/hrm_banner_top.png')}
					/>
				</div>
			</header>
			<section id="why-use" className="why-use">
				<div className="container">
					<div className="inner">
						<div className="title-block text-center">
							<h2 className="label cl1">TẠI SAO NÊN SỬ DỤNG?</h2>
							<span style={{ fontFamily: "helvetica neue" }} className="title">Vì sao chọn ACheckin?</span>
							<span className="line" />
						</div>

						<div className="content-block grid-3 text-left">
							{WHY_USE.map((item, i) => {
								return (
									<div key={`whyuse-feature${i}`} className="item">
										<div className="icon-item">
											<img alt={`whyuse${i}`} src={item.image} width={208} />
										</div>
										<h3 className="title-item">{item.title}</h3>
										<div className="content-item">
											{item.description}
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</section>

			<section id="feature" className="feature">
				<div className="bgsection" />
				<div className="title-block" style={{ alignContent: 'center' }}>
					<div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
						<span className="label cl1">TÍNH NĂNG NỔI BẬT</span>
						<span style={{ fontFamily: "helvetica neue" }} className="title">ACheckin có gì?</span>
						<span>
							<span className="line" />
						</span>
					</div>
				</div>
				<div className="container">
					<div className="inner">
						<FeatureSlide
							id="featurehot-v2"
							slideOption={optionsFeature1}
							features={FEATURES_SLIDE_1}
							title='Quản lý tiền lương' />
						<div className='d-none d-md-block' style={{ height: 85 }} />
						<FeatureSlide
							id="featurehot-v2-2"
							slideOption={optionsFeature2}
							features={FEATURES_SLIDE_2}
							rtl={true}
							title='Số hóa và lưu trữ toàn bộ hồ sơ nhân sự' />
					</div>
				</div>
			</section>

			<section>
				<div className="title-block" style={{ alignContent: 'center', marginLeft: 16, marginRight: 16, textAlign: 'center' }}>
					<div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: 56 }}>
						<span className="label cl1">TÍNH NĂNG NỔI BẬT</span>
						<span style={{ fontFamily: "helvetica neue" }} className="title">Quản lý nhân sự toàn diện với ACheckin HRM</span>
					</div>
				</div>
				<div className="container">
					<div className="inner">
						<FeatureStatic
							title={FEATURES_STATIC_1.title}
							description={FEATURES_STATIC_1.description}
							image={FEATURES_STATIC_1.image} />
						<div className='d-none d-md-block' style={{ height: 68 }} />
						<FeatureStatic
							title={FEATURES_STATIC_2.title}
							description={FEATURES_STATIC_2.description}
							rtl={true}
							image={FEATURES_STATIC_2.image} />
					</div>
				</div>
			</section>

			{/* Khách hàng */}
			<Partner />

			{/* Đăng ký nhận tư vấn  */}
			<div className='bg-free-consultation'>
				<FreeConsultation />
			</div>
			<Footer />
		</div>
	)
}

export default HrmScreen;
