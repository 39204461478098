//@ts-ignore
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Footer, Header, Helmet } from 'src/components';
import isMobile from 'src/utils/isMobile';
import FreeConsultation from '../Home/components/FreeConsultation';
import '../Home/styles.css';
import BannerDownload from './components/BannerDownload';
import './styles.css';

const DownloadScreen = (props: any) => {
	const [showAll, setShowAll] = useState(true);
	const recaptchaRef = React.useRef()

	useEffect(() => {
		if (isMobile.Android()) {
			$('.hideiOs').hide();
			$('.hideAll').hide();
			setShowAll(false);
		} else if (isMobile.iOS()) {
			$('.hideAndroid').hide();
			$('.hideAll').hide();
			setShowAll(false);
		}
	}, [])

	return (
		<div className="home-v1 downloadpage">
			<Helmet
				title="Hướng dẫn cách tải - download app ACheckin cho iOS & Android"
				contentDescription="Hướng dẫn cách download, tải về ứng dụng ACheckin cho điện thoại iOS, Android nhanh và chính xác nhất tại acheckin.vn"
				contentKeywords="download ACheckin, tải app acheckin, cách tải ứng dụng acheckin, tải acheckin cho iOS, tải acheckin cho Android"
				link="https://acheckin.vn/download"
			/>
			<header className={showAll ? "isAll" : "isMobile"}>
				<Header />
				<div className='padding-top-87'>
					<BannerDownload />
				</div>
			</header>

			<div className='container help-dowload'>
				<div className="title-block text-center">
					<h2 className="label cl1">Hướng dẫn cài đặt</h2>
					<h2 className="title">Ứng dụng ACheckin</h2>
					<span className="line" />
					<p>ACheckin hiện hỗ trợ cài đặt cho các thiết bị thông minh trên nền tảng iOS và Android, bạn có thể tải về bằng cách thực hiện các bước hướng dẫn sau:</p>
				</div>
				<div className="card-deck">
					<div className="card">
						<div className="card-body bg-card-download">
							<img alt="Hướng dẫn cách tải - download app ACheckin cho iOS & Android 10" src={require('src/assets/images/homev1/w5.png')} width={120} />
							<h5 className='title-help-download'>Bước 1: Tải về app ACheckin</h5>
							<p className='content-help-download'>
								Truy cập <b>ACheckin.vn</b> → Chọn <b>Download</b> → Chọn nút phiên bản <b>iOS</b>/<b>Android</b> phù hợp với thiết bị của bạn để tải về.
							</p>
							<p className='content-help-download'>
								Hoặc truy cập <b>App Store</b>/<b>Google Play</b> → Tìm từ khóa <b>ACheckin</b> → Chọn tải về trên thiết bị của bạn.
							</p>
						</div>
					</div>
					<div className="card">
						<div className="card-body bg-card-download">
							<img alt="Hướng dẫn cách tải - download app ACheckin cho iOS & Android 11" src={require('src/assets/images/homev1/w6.png')} width={120} />
							<h5 className='title-help-download'>Bước 2: Hoàn tất cài đặt</h5>
							<p className='content-help-download'>
								Sau khi tải về, bạn hãy mở ứng dụng <b>ACheckin</b> và tiếp tục thực hiện các bước cơ bản để đăng nhập và sử dụng.
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* Đăng ký nhận tư vấn  */}
			<div className='bg-free-consultation'>
				<FreeConsultation />
			</div>
			<Footer />
		</div>
	)
}

export default DownloadScreen;
