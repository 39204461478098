import React, { useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import "antd/dist/antd.css";
import { IFormDataContact } from './ModalHome';
import VarHelper from 'src/utils/VarHelper';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
interface Props {
}

const { Option } = Select

const FreeConsultationContact: React.FC<Props> = (props) => {
	const [form] = Form.useForm()
	const recaptchaRef = React.useRef()

	const onFinish = async (values: IFormDataContact) => {
		if (!values.fullname) return VarHelper.notificationError("Vui lòng nhập Họ và Tên")
		if (!values.email || !VarHelper.isEmailValid(values.email)) {
			return VarHelper.notificationError("Email không hợp lệ")
		}
		if (!values.phone_number || !VarHelper.isPhoneValid(values.phone_number)) {
			return VarHelper.notificationError("Số điện thoại không hợp lệ")
		}
		if (!values.company) return VarHelper.notificationError("Vui lòng nhập Doanh nghiệp/ Tổ chức")

		// @ts-ignore
		const captcha_token = await recaptchaRef.current.executeAsync()
		values.captcha_token = captcha_token

		fetch('https://api-inside.dev.acheckin.vn/v1/landing/users/request-consulting', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(values),
		}).then((data) => {
			notification.success({
				message: "Đăng ký thành công. Chúng tôi sẽ liên hệ với bạn sớm nhất!",
				placement: "topRight",
				top: 100,
			})
			form.resetFields()
		})
		// @ts-ignore
		recaptchaRef.current?.reset()
	}

	return (
		<div className='form-register'>
			<div className='title-register' style={{ textAlign: 'center' }}>Liên hệ tư vấn</div>
			<div>
				<Form form={form} name="control-hooks" onFinish={onFinish} className='row'>
					<Form.Item name="fullname" style={{ marginRight: 8, flex: 1 }}>
						<Input placeholder='Họ và tên*' className='input-antd' />
					</Form.Item>

					<Form.Item name="email" style={{ marginRight: 8, flex: 1 }}>
						<Input placeholder='Email*' className='input-antd' />
					</Form.Item>

					<Form.Item name="phone_number" style={{ marginRight: 8, flex: 1 }}>
						<Input placeholder='Số điện thoại*' className='input-antd' />
					</Form.Item>

					<Form.Item name="company" style={{ marginRight: 8, flex: 1 }}>
						<Input placeholder='Tên doanh nghiệp/Mã số thuế*' className='input-antd' />
					</Form.Item>

					<ReCAPTCHA
						ref={recaptchaRef}
						size="invisible"
						sitekey="6Le-0a0fAAAAALflrsxsttAnJyKyKtAuC_Dc2rT2"
					/>

					<div className='text-center'>
						<Button htmlType='submit' className='button-registration'>
							ĐĂNG KÝ
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default FreeConsultationContact;