export const LINK_IOS = 'https://itunes.apple.com/vn/app/acheckin-hrm-office/id1613682309?mt=8';
export const LINK_ANDROID = 'https://play.google.com/store/apps/details?id=acheckin.hrm';
// export const LINK_WINDOWS = 'https://drive.google.com/file/d/1Eb7pMCeCP3I5bcVYcS8yoO6nH3GHSxzk';
// export const LINK_UBUNTU = 'https://drive.google.com/open?id=1o_G3bWETMjBjQN8A3VzOI7XLkRljAzEa';
// export const LINK_DESKTOP_MAC = 'https://cdn.acheckin.vn/software/ACheckin%201.2.0.dmg';
// export const LINK_DESKTOP_WINDOWS = 'https://cdn.acheckin.vn/software/ACheckin%201.2.0.exe';
// export const LINK_DESKTOP_WINDOWS32 = 'https://cdn.acheckin.vn/software/ACheckin%201.2.0.exe';
// export const LINK_DESKTOP_WINDOWS64 = 'https://cdn.acheckin.vn/software/ACheckin%201.2.0.exe';

export const LINK_WINDOWS = 'https://download.acheckin.io/';
export const LINK_UBUNTU = 'https://download.acheckin.io/';
export const LINK_DESKTOP_MAC = 'https://download.acheckin.io/';
export const LINK_DESKTOP_WINDOWS = 'https://download.acheckin.io/';
export const LINK_DESKTOP_WINDOWS32 = 'https://download.acheckin.io/';
export const LINK_DESKTOP_WINDOWS64 = 'https://download.acheckin.io/';

export const TYPE = {
    LOGIN: 'login',
    OTP: 'otp',
    REGISTER: 'register'
}

export const BUILD_SCHEME: 'DEV' | 'PROD' = window.location.host.indexOf("dev") !== -1 || window.location.host.indexOf("localhost") !== -1 ? "DEV" : "PROD";

export const CUSTOMERS_PARTNER = [
    {
        images: [
            {
                "data": [
                    { img: require('src/assets/images/homev1/logos/1.png') },
                    { img: require('src/assets/images/homev1/logos/2.png') },
                    { img: require('src/assets/images/homev1/logos/3.png') },
                    { img: require('src/assets/images/homev1/logos/4.png') },
                    { img: require('src/assets/images/homev1/logos/5.png') },
                ]
            },
            {
                "data": [
                    { img: require('src/assets/images/homev1/logos/6.png') },
                    { img: require('src/assets/images/homev1/logos/hafa.png') },
                    { img: require('src/assets/images/homev1/logos/luxy_care.png') },
                    { img: require('src/assets/images/homev1/logos/on_point.png') },
                    { img: require('src/assets/images/homev1/logos/10.png') },
                ]
            },
            {
                "data": [
                    { img: require('src/assets/images/homev1/logos/11.png') },
                    { img: require('src/assets/images/homev1/logos/12.png') },
                    { img: require('src/assets/images/homev1/logos/13.png') },
                    { img: require('src/assets/images/homev1/logos/14.png') },
                    { img: require('src/assets/images/homev1/logos/15.png') },
                ]
            }
        ]
    },
    {
        images: [
            {
                "data": [
                    { img: require('src/assets/images/homev1/logos/16.png') },
                    { img: require('src/assets/images/homev1/logos/17.png') },
                    { img: require('src/assets/images/homev1/logos/18.png') },
                    { img: require('src/assets/images/homev1/logos/19.png') },
                    { img: require('src/assets/images/homev1/logos/20.png') },
                ]
            },
            {
                "data": [
                    { img: require('src/assets/images/homev1/logos/best_express.png') },
                    { img: require('src/assets/images/homev1/logos/vse.png') },
                    { img: require('src/assets/images/homev1/logos/quy_chi.png') },
                    { img: require('src/assets/images/homev1/logos/harper7coffee.png') },
                    { img: require('src/assets/images/homev1/logos/ha_holding.png') },
                ]
            },
        ]
    }
]

export const CUSTOMERS = [
    { img: require('src/assets/images/homev1/logos/1.png') },
    { img: require('src/assets/images/homev1/logos/2.png') },
    { img: require('src/assets/images/homev1/logos/3.png') },
    { img: require('src/assets/images/homev1/logos/4.png') },
    { img: require('src/assets/images/homev1/logos/5.png') },
    { img: require('src/assets/images/homev1/logos/6.png') },
    { img: require('src/assets/images/homev1/logos/hafa.png') },
    { img: require('src/assets/images/homev1/logos/luxy_care.png') },
    { img: require('src/assets/images/homev1/logos/on_point.png') },
    { img: require('src/assets/images/homev1/logos/10.png') },
    { img: require('src/assets/images/homev1/logos/11.png') },
    { img: require('src/assets/images/homev1/logos/12.png') },
    { img: require('src/assets/images/homev1/logos/13.png') },
    { img: require('src/assets/images/homev1/logos/14.png') },
    { img: require('src/assets/images/homev1/logos/15.png') },
    { img: require('src/assets/images/homev1/logos/16.png') },
    { img: require('src/assets/images/homev1/logos/17.png') },
    { img: require('src/assets/images/homev1/logos/18.png') },
    { img: require('src/assets/images/homev1/logos/19.png') },
    { img: require('src/assets/images/homev1/logos/20.png') },
    { img: require('src/assets/images/homev1/logos/best_express.png') },
    { img: require('src/assets/images/homev1/logos/vse.png') },
    { img: require('src/assets/images/homev1/logos/quy_chi.png') },
    { img: require('src/assets/images/homev1/logos/harper7coffee.png') },
    { img: require('src/assets/images/homev1/logos/ha_holding.png') },
];

export const CITY = [
    "Hà Nội",
    "TP Hồ Chí Minh",
    "An Giang",
    "Bà Rịa – Vũng Tàu",
    "Bắc Giang",
    "Bắc Kạn",
    "Bạc Liêu",
    "Bắc Ninh",
    "Bến Tre",
    "Bình Định",
    "Bình Dương",
    "Bình Phước",
    "Bình Thuận",
    "Cà Mau",
    "Cần Thơ",
    "Cao Bằng",
    "Đà Nẵng",
    "Đắk Lắk",
    "Đắk Nông",
    "Điện Biên",
    "Đồng Nai",
    "Đồng Tháp",
    "Gia Lai",
    "Hà Giang",
    "Hà Nam",
    "Hà Tĩnh",
    "Hải Dương",
    "Hải Phòng",
    "Hậu Giang",
    "Hòa Bình",
    "Hưng Yên",
    "Khánh Hòa",
    "Kiên Giang",
    "Kon Tum",
    "Lai Châu",
    "Lâm Đồng",
    "Lạng Sơn",
    "Lào Cai",
    "Long An",
    "Nam Định",
    "Nghệ An",
    "Ninh Bình",
    "Ninh Thuận",
    "Phú Thọ",
    "Phú Yên",
    "Quảng Bình",
    "Quảng Nam",
    "Quảng Ngãi",
    "Quảng Ninh",
    "Quảng Trị",
    "Sóc Trăng",
    "Sơn La",
    "Tây Ninh",
    "Thái Bình",
    "Thái Nguyên",
    "Thanh Hóa",
    "Thừa Thiên Huế",
    "Tiền Giang",
    "Trà Vinh",
    "Tuyên Quang",
    "Vĩnh Long",
    "Vĩnh Phúc",
    "Yên Bái"
]

export const BANNERS = [
    {
        image: require('src/assets/hrm/slide1.png'),
        title: 'Quản lý tiền lương',
        description: 'Dễ dàng quản lý và theo dõi tiền lương của từng nhân sự, bộ phận phòng ban. Thiết lập cấu hình tính lương, thang bảng lương theo nhu cầu doanh nghiệp. ',
        button1_title: 'Tư vấn',
        button1_href: '/contact',
        button2_title: 'Tham khảo tính năng',
        button2_href: 'https://tinhnang.acheckin.vn/camera',
    },
    {
        image: require('src/assets/hrm/slide2.png'),
        title: 'Quản lý và lưu trữ hồ sơ nhân sự',
        description: 'Số hóa và lưu trữ hồ sơ nhân sự tập trung. Chuẩn hóa và quản lý thông tin nhân sự khoa học, phục vụ công tác khai thác thông tin trong các hoạt động quản trị nhân lực trong doanh nghiệp',
        button1_title: 'Tư vấn',
        button1_href: '/contact',
        button2_title: 'Tham khảo tính năng',
        button2_href: 'https://tinhnang.acheckin.vn/camera',
    },
    {
        image: require('src/assets/hrm/slide3.png'),
        title: 'Giải pháp chấm công nhận diện khuôn mặt',
        description: 'Giải pháp chấm công hiện đại với việc sử dụng camera tích hợp công nghệ trí tuệ nhân tạo, khắc phục mọi nhược điểm của các hình thức cũ. Đồng thời, tạo ra trải nghiệm chấm công thông minh, chính xác và thú vị',
        button1_title: 'Tư vấn',
        button1_href: '/contact',
        button2_title: 'Tham khảo tính năng',
        button2_href: '/feature?tab=quan-ly-nhan-su',
    },
    {
        image: require('src/assets/hrm/slide4.png'),
        title: 'Hỗ trợ xây dựng văn hoá doanh nghiệp thời đại số',
        description: 'Cung cấp công cụ truyền thông và triển khai hoạt động nội bộ giúp tăng tương tác và sự gắn kết của nhân viên. Công cụ kết hợp linh hoạt giữa trực tuyến và trực tiếp nhằm đa dạng hình thức, tiết kiệm chi phí, tối ưu hiệu quả.',
        button1_title: 'Tư vấn',
        button1_href: '/contact',
        button2_title: 'Tham khảo tính năng',
        button2_href: '/feature?tab=quan-ly-nhan-su',
    },
    {
        image: require('src/assets/hrm/slide5.png'),
        title: 'Giải pháp chấm công thông minh',
        description: 'Chấm công bằng QR code và các hình thức khác như định vị GPS, Wifi nội bộ tiện lợi và chính xác. Giải pháp giúp tiết kiệm thời gian, chi phí cài đặt và vận hành cho doanh nghiệp.',
        button1_title: 'Tư vấn',
        button1_href: '/contact',
        button2_title: 'Tham khảo tính năng',
        button2_href: '/feature?tab=quan-ly-nhan-su',
    },
    {
        image: require('src/assets/hrm/slide6.png'),
        title: 'Quản lý ca làm việc, đăng kí ca, xoay ca, đổi ca nhanh chóng',
        description: 'Quản lý ca làm việc, đăng kí, xoay ca đổi ca dễ dàng trên ứng dụng. Cập nhật chính xác dữ liệu ca, tùy chỉnh và xuất báo cáo chấm công theo thời gian thực.',
        button1_title: 'Tư vấn',
        button1_href: '/contact',
        button2_title: 'Tham khảo tính năng',
        button2_href: '/feature?tab=truyen-thong-noi-bo',
    }
]

export const HOT_FEATURES = [
    { title: 'Hình thức chấm công đa dạng', image: require('src/assets/hrm/phone1.png') },
    { title: 'Tạo ca đổi ca làm việc dễ dàng', image: require('src/assets/hrm/phone2.png') },
    { title: 'Duyệt đơn nhanh chóng', image: require('src/assets/hrm/phone3.png') },
    { title: 'Xem phiếu lương trên app', image: require('src/assets/hrm/phone4.png') },
]
