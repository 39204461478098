import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Footer, Header, Helmet } from 'src/components';
import FreeConsultation from '../Home/components/FreeConsultation';
import SlideItem from '../Home/components/SlideItem';
import '../Home/styles.css';
import FeatureSlide, { IFeatureSlide } from './components/FeatureSlide';
import './styles.css';

const TABS = [
	{ id: 'quan-ly-nhan-su', title: 'Quản lý nhân sự' },
	{ id: 'quan-ly-du-an', title: 'Quản lý dự án' },
	{ id: 'quan-ly-tai-san', title: 'Quản lý tài sản' },
	{ id: 'quan-ly-he-thong', title: 'Quản lý hệ thống' },
	{ id: 'truyen-thong-noi-bo', title: 'Truyền thông nội bộ' },
]

const SLIDES: { [key: string]: IFeatureSlide[] } = {
	'quan-ly-nhan-su': [
		{
			title: 'Chấm công thông minh',
			items: [
				"Đa dạng hình thức chấm công như QR Code, Camera AI, Vị trí và Wifi",
				"Không cần lắp đặt thiết bị phức tạp.",
				"Thống kê hoạt động chấm công theo thời gian thực",
			],
			image: require('src/assets/hrm/feature_slides/1-0.png'),
		},
		{
			title: 'Quản lý ngày công',
			items: [
				"Dễ dàng theo dõi thông tin chấm công như giờ vào - tan ca, ngày phép, ngày công",
				"Báo nghỉ, đi muộn, làm thêm giờ, làm việc tại nhà tiện lợi trên ứng dụng",
				"Duyệt đơn xin phép của nhân viên chỉ với 1 chạm",

			],
			image: require('src/assets/hrm/feature_slides/1-1.png'),
		},
		{
			title: 'Tạo ca làm việc linh hoạt',
			items: [
				"Nhân viên chủ động đăng kí ca, xin phép xoay ca, đổi ca nhanh chóng",
				"Quản lý duyệt yêu cầu ngay trên ứng dụng",
				"Tùy chỉnh thiết lập và xuất báo cáo chấm công theo nhu cầu",
			],
			image: require('src/assets/hrm/feature_slides/1-2.png'),
		},
		{
			title: 'Quản lý thông tin nhân viên',
			items: [
				"Số hoá lưu trữ hồ sơ nhân viên",
				"Quản lý thông tin nhân viên cấp dưới ngay trên ứng dụng",
			],
			image: require('src/assets/hrm/feature_slides/1-3.png'),
		},
	],
	'quan-ly-du-an': [
		{
			title: 'Quản lý thông tin dự án',
			items: [
				"Tạo và thêm nhân viên vào các dự án",
				"Đánh giá thời gian phân bổ thực hiện từng dự án",
			],
			image: require('src/assets/hrm/feature_slides/3-0.png'),
		},
		{
			title: 'Thống kê danh sách dự án',
			items: [
				'Thống kê danh sách và trạng thái các dự án được triển khai trong doanh nghiệp',
			],
			image: require('src/assets/hrm/feature_slides/3-0.png'),
		}
	],
	'quan-ly-tai-san': [
		{
			title: 'Quản lý danh sách cơ sở vật chất',
			items: [
				"Đặt lịch sử dụng nhanh chóng các phòng chức năng: phòng họp, thư viện,..",
				"Nắm bắt trạng thái sử dụng cơ sở vật chất trong văn phòng",
			],
			image: require('src/assets/hrm/feature_slides/4-0.png'),
		},
	],
	'quan-ly-he-thong': [
		{
			title: 'Quản lý mạng wifi nội bộ trong văn phòng',
			items: [
				"Quản lý người dùng và thiết bị sử dụng hệ thống mạng",
				"Wifi marketing trong nội bộ doanh nghiệp",
				"Quản lý và nâng cấp hệ thống mạng dễ dàng",
			],
			image: require('src/assets/hrm/feature_slides/5-0.png'),
		},
	],
	'truyen-thong-noi-bo': [
		{
			title: 'Công cụ truyền thông nội bộ',
			items: [
				"Bảng tin nội bộ cho phép cập nhật thông tin, tương tác dễ dàng",
				"Thông báo qua điện thoại đến nhân viên nhanh chóng",
				"Cập nhật thông tin nội bộ qua hình ảnh, video trên máy chấm công",
			],
			image: require('src/assets/hrm/feature_slides/2-0.png'),
		},
		{
			title: 'Thúc đẩy, khuyến khích tinh thần của nhân viên',
			items: [
				"Các bảng vinh danh đi làm sớm, chuyên cần tháng",
				"Công ty gửi lời chúc mừng nhân sự dịp sinh nhật",
			],
			image: require('src/assets/hrm/feature_slides/2-1.png'),
		},
		{
			title: 'Trò chơi tương tác cho sự kiện tập thể',
			items: [
				`Game kết hợp giữa online và offline, không giới hạn người tham gia dành cho sự kiện tập thể như team building, liên hoan, lễ tết,...`,
			],
			image: require('src/assets/hrm/feature_slides/2-2.png'),
		},
	],
}

const FeatureScreen = (props: any) => {
	const [currentTab, setCurrentTab] = useState(TABS[0].id);
	const recaptchaRef = React.useRef()

	const ITEM = {
		image: require('src/assets/hrm/feature_img.png'),
		title: 'Tổng quan về giải pháp',
		description: 'Khám phá những tính năng vượt trội của Giải pháp quản trị nhân sự HRM ACheckin',
		button1_title: 'Dùng thử miễn phí',
		button1_href: '/contact',
		button2_title: 'Nhận tư vấn',
		button2_href: 'https://tinhnang.acheckin.vn/camera',
	}

	return (
		<div className="home-v1 contactpage feature-page anchor-link">
			<Helmet
				title="Tính năng quản lý nhân sự, dự án, truyền thông nội bộ của ACheckin"
				contentDescription="ACheckin tích hợp các tính năng: quản lý nhân sự thông minh, quản lý dự án, quản lý hệ thống, truyền thông nội bộ, gắn kết nhân viên hiệu quả"
				contentKeywords="tính năng ACheckin, tính năng của app ACheckin, ACheckin tính năng, tinh nang cua acheckin"
				link="https://acheckin.vn/feature"
			/>

			<header>
				<Header />
				<div className='padding-top-87'>
					<SlideItem
						title={ITEM.title}
						description={ITEM.description}
						button1_href={ITEM.button1_href}
						button2_href={ITEM.button2_href}
						button1_title={ITEM.button1_title}
						button2_title={ITEM.button2_title}
						image={ITEM.image}
					/>
				</div>

			</header>

			<div className="tab-feautre">
				<div className="container">
					<ul className="nav ">
						{TABS.map((item, i) => (
							<li key={`tab${i}`} className="nav-item"
								onClick={() => setCurrentTab(item.id)}>
								<a className={classNames('nav-link', { active: currentTab === item.id })} href={`#${item.id}`}>{item.title}</a>
							</li>
						))}
					</ul>
				</div>
			</div>

			{TABS.map((item, i) => {
				return (
					<FeatureSlide
						key={`featureslide${i}`}
						id={item.id}
						title={item.title}
						features={SLIDES[item.id]}
						index={i}
					/>
				)
			})}

			{/* Đăng ký nhận tư vấn  */}
			<div id='sign-up-home' className='bg-free-consultation'>
				<FreeConsultation />
			</div>

			<Footer />
		</div>
	)
}

export default FeatureScreen;
