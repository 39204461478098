import React from 'react';
import TrackingService from 'src/services/Tracking.Service';

interface Props { }

const InfomationContact: React.FC<Props> = () => {
	return (
		<div className="infomation-contact">
			<div className="container">
				<div className="title-block text-center">
					<span className="label cl1">Hỗ trợ</span>
					<span className="title">Liên hệ với chúng tôi</span>
					<span className="line" />
				</div>

				<div className="inner">
					<div className="row">
						<div className="col-sm-4">
							<div className="box d-flex">
								<div className="icon">
									<img alt="" src={require('src/assets/images/homev1/icons8-chat.png')} height={48} />
								</div>
								<div className="content">
									<h6>Hotline</h6>
									<p className="cl1"><a onClick={() => TrackingService.onTrackEvent("Conntact", "Hotline")} rel="noreferrer">0888684366</a></p>
								</div>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="box d-flex">
								<div className="icon">
									<img alt="" src={require('src/assets/images/homev1/icons8-email.png')} height={48} />
								</div>
								<div className="content">
									<h6>Góp ý & hỗ trợ</h6>
									<p className="cl1"><a onClick={() => TrackingService.onTrackEvent("Conntact", "Góp ý & hỗ trợ")} href="mailto:sale@acheckin.vn" rel="noreferrer">sale@acheckin.vn</a></p>
								</div>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="box d-flex">
								<div className="icon">
									<img alt="" src={require('src/assets/images/homev1/icons8-facebook.png')} height={48} />
								</div>
								<div className="content">
									<h6>Fanpage</h6>
									<p className="cl1"><a onClick={() => TrackingService.onTrackEvent("Conntact", "Fanpage")} href="https://fb.com/acheckin.appota" target="_blank" rel="noreferrer">https://fb.com/acheckin.appota</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	);
};

export default InfomationContact;