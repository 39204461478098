
import React from 'react';
import { NavLink } from 'react-router-dom';

const INFOS = [
  { title: 'Tên công ty:', value: 'CÔNG TY CỔ PHẦN PHẦN MỀM ACHECKIN' },
  { title: 'Địa chỉ:', value: 'Tầng 6, Tòa nhà 16-18, ngõ 71, phố Láng Hạ, phường Thành Công, quận Ba Đình, Thành phố Hà Nội, Việt Nam' },
  [{ title: 'Hotline:', value: '0888684366' }, { title: 'Email:', value: 'sale@acheckin.vn' }]
]

const FEATURES = [
  { title: 'Trang chủ', href: '/', key: 'home' },
  { title: 'Hướng dẫn', href: 'https://help.acheckin.io/', key: 'faq' },
  { title: 'Download', href: '/download', key: 'download' },
  { title: 'Tin tức', href: 'http://news.acheckin.vn/', key: 'news' },
  { title: 'Liên hệ', href: '/contact', key: 'contact' },
  { title: 'Tính năng', href: '/feature' },
  { title: 'Khách hàng', href: '/' },
  { title: 'Chính sách', href: '/policy' },
]

const Footer: React.FC<{}> = () => {
  return (
    <footer id="contact" className='bg-white border-top' >
      <div className="container border-bottom">
        <div className="row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <div className="col-md-6">
            <NavLink to="/">
              <img alt="ACheckin - Ứng dụng chấm công và quản lý tài nguyên Doanh Nghiệp" src={require('src/assets/images/homev1/logo_footer.png')} height={52} />
            </NavLink>

            {INFOS.map((info: any, i: number) => {
              if (info.title) {
                return (
                  <p style={{ marginBottom: '0.3rem' }} key={`info${i}`}>
                    <span className='title-footer'>{info.title}</span> <span className='text-right-footer'>{info.value}</span>
                  </p>
                )
              }
              return (
                <p style={{ marginBottom: '0.3rem' }} key={`info${i}`}>
                  {info.map((row: any, j: number) => {
                    return (
                      <span key={`info${i}${j}`}>
                        <span className='title-footer'>{row.title}</span> <span className='text-right-footer'>{row.value}{j === info.length - 1 ? '' : ` | `}</span>
                      </span>
                    )
                  })}
                </p>
              )
            })}
            <a rel="noreferrer" target="_blank"
              href="http://online.gov.vn/Home/WebDetails/75650?fbclid=IwAR37ZXAY2eYnUreMTaiJT8np3rNO0g7msDC9Wpq1DOszIfvuvwRuq_dauug&AspxAutoDetectCookieSupport=1"
            >
              <img alt="logo verified" style={{ marginTop: 10 }} src={require('src/assets/images/homev1/logoVerified.png')} width="171" />
            </a>
          </div>
          <div className="col-md-4 offset-md-2">
            <h3 className='title-category'>Danh mục</h3>
            <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                {FEATURES.slice(0, 4).map((feature, i) => {
                  return (
                    <a
                      key={`feature_${feature.key}`}
                      className="text-right-footer"
                      target="_blank"
                      href={feature.href}
                      rel="noreferrer"
                    >
                      {feature.title}
                    </a>
                  )
                })}
              </div>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                {FEATURES.slice(4, 8).map((feature, i) => {
                  return (
                    <a
                      key={`${i + 1}_fea`}
                      className="text-right-footer"
                      target="_blank"
                      rel="noreferrer"
                      href={feature.href}
                    >
                      {feature.title}
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-bottom'>
        Copyright 2019 by ACheckin. All rights reserved
      </div>
    </footer>
  );
};

export default Footer;