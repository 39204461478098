import React from 'react';
import IcContact from 'src/assets/images/homev1/slide-contact.svg';

const BannerContact: React.FC<{}> = () => {
	return (
		<div className="container padding-top-87 mt-4">
			<div className="header-middle row">
				<div className="col-md-7">
					<div className="txt">
						<h6>
							Liên Hệ Với Chúng Tôi
						</h6>
						<p>
							Mọi đóng góp ý kiến và phản hồi từ bạn sẽ góp phần để chúng tôi hoàn thiện giải pháp quản lý tài nguyên doanh nghiệp. <br /> <br />
							Hãy kết nối với Team ACheckin qua các kênh sau để được tư vấn thông tin và hỗ trợ sử dụng ACheckin nhé.
						</p>

					</div>
				</div>
				<div className="col-md-5">
					<div className="img-slidecontact text-md-right">
						<img alt="Liên hệ với ACheckin 1" src={IcContact} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default BannerContact;