import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native-web';
import isDev from 'src/utils/isDev';

const RedirectScreen = (props: any) => {
	useEffect(() => {
		window.location.href = `https://hrm.${isDev() ? 'dev.' : ''}acheckin.io`;
	}, [])
	return (
		<View style={styles.container}>

		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'grey',
	}
})

export default RedirectScreen;
