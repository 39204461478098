import { LINK_ANDROID, LINK_IOS } from 'src/constants';
import IcAppStore from 'src/assets/images/homev1/ic-ios.svg';
import IcPlayStore from 'src/assets/images/homev1/ic-google-play.svg';

interface Props {
}

const BannerDownload = (props: Props) => {

    return (
        <div className="container itemSlider">
            <div className="row">
                <div className="col-md-5 header-middle">
                    <div className="txt">
                        <h1>
                            Tải về ACheckin
                        </h1>
                        <p>
                            ACheckin hỗ trợ phiên bản Ứng dụng ACheckin dành cho các thiết bị thông minh phổ biến chạy nền tảng Android và iOS cũng như phiên bản IoT ( Máy Checkin) dành cho thiết bị PC chạy nền tảng Windows, Android và Linux khi lắp đặt hệ thống
                        </p>
                        <div className="btn-download">
                            <a rel="noreferrer" className="btn hideiOs" href={LINK_IOS} target="_blank" >
                                <img alt="Hướng dẫn cách tải - download app ACheckin cho iOS & Android 1" src={IcAppStore} />
                                Phiên bản iOS
                            </a>
                            <a rel="noreferrer" className="btn hideAndroid" href={LINK_ANDROID} target="_blank">
                                <img alt="Hướng dẫn cách tải - download app ACheckin cho iOS & Android 2" src={IcPlayStore} />
                                Phiên bản Android
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <img alt="Hướng dẫn cách tải - download app ACheckin cho iOS & Android 6" src={require('src/assets/hrm/download_img.png')} />
                </div>
            </div>
        </div>
    )
}

export default BannerDownload