// @ts-ignore
import OwlCarousel from 'react-owl-carousel2';
import classNames from 'classnames';
import 'src/assets/bootstrap/css/bootstrap.min.css';


interface IFeature {
	title: string,
	image: any
}

export interface IFeatureSlideProps {
	id: 'featurehot-v2' | 'featurehot-v2-2',
	title: string,
	features: IFeature[],
	slideOption: any,
	rtl?: boolean
}




const FeatureSlide = (props: IFeatureSlideProps) => {
	return (
		<div className='row' style={props.rtl ? { flexDirection: 'row-reverse' } : {}}>
			<div className="col-sm-6">

				<div className="infosection">
					<div>
						<label style={{ fontSize: 24, fontWeight: '700', color: 'white', marginBlock: 32 }}>{props.title}</label>
					</div>
					<div className={props.id}>
						{props.features.map((item, i) => {
							return (
								<div className={classNames(`feature-dot${props.id}`, { active: i === 0, [`feature${i + 1}`]: true })}>
									<img src={require('src/assets/hrm/hrm_checked_item.png')} style={{ width: 19, marginRight: 10 }} />
									{item.title}
								</div>
							)
						})}
					</div>
				</div>

			</div>

			<div className="col-sm-6 p-0">
				<OwlCarousel options={props.slideOption}  >
					{props.features.map((item, i) => (
						<div key={`phone${i}`} className="item">
							<a href="javascript:;" >
								<img
									alt="ACheckin: App chấm công và quản lý tài nguyên Doanh Nghiệp"
									src={item.image}
								/>
							</a>
						</div>
					))}
				</OwlCarousel>
			</div>
		</div>
	)
}
export default FeatureSlide;