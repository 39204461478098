import { useState } from 'react';
import { Link } from 'react-router-dom';
interface Props {
    image: any,
    title?: string,
    description?: string,
    button1_title?: string,
    button2_title?: string,
    button1_href?: string,
    button2_href?: string,
    component?: any,
}

const SlideItem = (props: Props) => {
    return (
        <div className="container itemSlider itemSlider-hrm">
            <div className="row">
                <div className="col-md-5 header-middle">
                    <div className="txt">
                        <h1>
                            {props.title}
                        </h1>
                        <p>
                            {props.description}
                        </p>
                        <div className="banner-hrm">
                            <Link to={'/register'} target={"_blank"} className="btnHeader_Fill color-trial">
                                Dùng thử miễn phí
                            </Link>

                            <a className="btnHeader_Fill ml-3" href={"https://tinhnang.acheckin.vn/camera"} target="blank">
                                Khám phá Tính năng
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <img alt="ACheckin: App chấm công và quản lý tài nguyên Doanh Nghiệp 1" src={props.image} />
                </div>
            </div>
        </div>
    )
}

export default SlideItem;
