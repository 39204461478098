import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import OwlCarousel from 'react-owl-carousel2';
import { SlideArrow } from 'src/components';
import { HOT_FEATURES, LINK_ANDROID, LINK_IOS } from 'src/constants';

interface Props { }

const options = {
	loop: true,
	margin: 0,
	autoplay: true,
	dots: true,
	rewind: true,
	dotsContainer: ".featurehot",
	responsive: {
		0: {
			items: 1
		},
	}
};


const Feature: React.FC<Props> = () => {
	return (
		<section id="feature" className="featureHome feature-home">
			<div className="bgsection" />
			<div className="container">
				<div className="inner">
					<div className="row">
						<div className="col-sm-4 pr-0 pl-5">
							<div className="infosection">
								<div className="title-block text-left">
									<span className="label cl1">khám phá</span>
									<span style={{ fontFamily: "helvetica neue" }} className="title">Tính năng nổi bật <br /> của ACheckin</span>
									<span className="line" />
								</div>
								<div className="featurehot">
									{HOT_FEATURES.map((item, i) => {
										return (
											<div key={`feature${i + 1}`} className={classNames('feature-dot', { active: i === 0, [`feature${i + 1}`]: true })}>
												<SlideArrow /> {item.title}
											</div>
										)
									})}
								</div>
							</div>
						</div>

						<div className="col-sm-4 p-0">
							<div className="slidedemo" style={{ display: 'flex', justifyContent: 'center' }}>
								<div className="images">
									<img alt="phone" src={require('src/assets/hrm/feature_slides/phone-bg.png')} />
									<div className="innerslide">
										<OwlCarousel options={options}  >
											{HOT_FEATURES.map((item, i) => (
												<div key={`phone${i}`} className="item">
													<img
														alt="ACheckin: App chấm công và quản lý tài nguyên Doanh Nghiệp"
														src={item.image}
													/>
												</div>
											))}
										</OwlCarousel>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-4 pl-0">
							<div className="infosection infosectionright">
								<div className="title-block text-left">
									<img alt="" src={require('src/assets/images/homev1/icon-devices.png')} width={50} />

								</div>
								<div className="content-block">
									<span><b>ACheckin cho thiết bị di động</b></span> <br />
									<span>Có sẵn trên các thiết bị chạy hệ điều hành <br /> Android hay iOS.  Tải về tại đây.</span>

								</div>
								<div className="group-download d-flex">
									<a rel="noreferrer" className="hideiOs" href={LINK_IOS} target="_blank">  <img alt="" src={require('src/assets/images/homev1/btn-appstore.png')} width={150} /> </a>
									<a rel="noreferrer" className="hideAndroid" href={LINK_ANDROID} target="_blank">  <img alt="" src={require('src/assets/images/homev1/btn-googleplay.png')} width={150} /> </a>
								</div>
							</div>


						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Feature;