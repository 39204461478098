import React, { useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import "antd/dist/antd.css";
import { IFormDataContact } from './ModalHome';
import VarHelper from 'src/utils/VarHelper';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
interface Props {
}

const { Option } = Select

const FreeConsultation: React.FC<Props> = (props) => {
	const [form] = Form.useForm()
	const recaptchaRef = React.useRef()

	const onFinish = async (values: IFormDataContact) => {
		if (!values.fullname) return VarHelper.notificationError("Vui lòng nhập Họ và Tên")
		if (!values.email || !VarHelper.isEmailValid(values.email)) {
			return VarHelper.notificationError("Email không hợp lệ")
		}
		if (!values.phone_number || !VarHelper.isPhoneValid(values.phone_number)) {
			return VarHelper.notificationError("Số điện thoại không hợp lệ")
		}
		if (!values.company) {
			return VarHelper.notificationError("Vui lòng nhập Doanh nghiệp/ Tổ chức")
		}

		// @ts-ignore
		const captcha_token = await recaptchaRef.current.executeAsync()
		values.captcha_token = captcha_token

		fetch('https://api-inside.dev.acheckin.vn/v1/landing/users/request-consulting', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(values),
		}).then((data) => {
			notification.success({
				message: "Đăng ký thành công. Chúng tôi sẽ liên hệ với bạn sớm nhất!",
				placement: "topRight",
				top: 100,
			})
			form.resetFields()
		})
		// @ts-ignore
		recaptchaRef.current?.reset()
	}

	return (
		<div className='container padding-60'>
			<div className='row'>
				<div className='col-md-6 col-xl-7'>
					<div className='title-free-consultation'>
						Đăng ký nhận tư vấn & trải nghiệm miễn phí Giải pháp quản trị nâng cao trải nghiệm nhân sự ACheckin ngay hôm nay!
					</div>
					<div className='content-free-consultation'>
						HRM ACheckin - giải pháp SaaS giúp doanh nghiệp nâng cao hiệu quả quản trị và phát triển tổ chức dựa trên nền tảng công nghệ. Giải pháp dễ sử dụng, dễ triển khai, phù hợp với mọi quy mô và loại hình doanh nghiệp.
					</div>
				</div>
				<div className='col-md-6 col-xl-5'>
					<div className='form-register'>
						<div className='title-register'>Đăng ký nhận tư vấn miễn phí</div>
						<div>
							<Form form={form} name="control-hooks" onFinish={onFinish}>
								<Form.Item name="fullname">
									<Input placeholder='Họ và tên*' className='input-antd' />
								</Form.Item>

								<Form.Item name="email">
									<Input placeholder='Email*' className='input-antd' />
								</Form.Item>

								<Form.Item name="phone_number">
									<Input placeholder='Số điện thoại*' className='input-antd' />
								</Form.Item>

								<Form.Item name="company">
									<Input placeholder='Doanh nghiệp/ Tổ chức*' className='input-antd' />
								</Form.Item>

								<ReCAPTCHA
									ref={recaptchaRef}
									size="invisible"
									sitekey="6Le-0a0fAAAAALflrsxsttAnJyKyKtAuC_Dc2rT2"
								/>

								<div className='text-center'>
									<Button htmlType='submit' className='button-registration'>
										ĐĂNG KÝ
									</Button>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};

export default FreeConsultation;