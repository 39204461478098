import 'src/assets/bootstrap/css/bootstrap.min.css';

export interface IFeatureStaticProps {
	title: string,
	description: string[],
	image: string,
	rtl?: boolean,
}




const FeatureStatic = (props: IFeatureStaticProps) => {
	return (
		<div className='row' style={props.rtl ? { flexDirection: 'row-reverse' } : {}}>
			<div className="col-sm-6">

				<div className="infosection">
					<div>
						<label style={{ fontSize: 24, fontWeight: '700', marginBlock: 32 }}>{props.title}</label>
					</div>
					{props.description.map((item) => {
						return (
							<div style={{ paddingTop: 12, paddingBottom: 12 }}>
								<img src={require('src/assets/hrm/hrm_checked_item.png')} style={{ width: 19, marginRight: 10 }} />
								{item}
							</div>
						)
					})}
				</div>

			</div>

			<div className="col-sm-6 p-0">
				<img src={props.image} />
			</div>
		</div>
	)
}
export default FeatureStatic;