import { Grid } from 'antd';
import React from 'react';

const { useBreakpoint } = Grid

const WHY_USE = [
	{
		title: 'Số hóa hồ sơ nhân sự',
		description: 'Dễ dàng tra cứu, an toàn, bảo mật khi quản lý và lưu trữ hồ sơ nhân sự tập trung trên một nền tảng duy nhất.',
		image: require('src/assets/hrm/w1.png')
	},
	{
		title: 'Quản lý nhân sự từ xa',
		description: 'Quản lý nhân sự từ xa hiệu quả với định vị bằng hình ảnh, biểu đồ thống kê trực quan, cập nhật theo thời gian thực.',
		image: require('src/assets/hrm/w5.png')
	},
	{
		title: 'Quản lý tiền lương',
		description: 'Tự động tính lương, tạo phiếu lương gửi cho nhân viên theo thiết lập. Quản lý và theo dõi quỹ lương của từng phòng ban.',
		image: require('src/assets/hrm/w3.png')
	},
	{
		title: 'Quản lý tài nguyên',
		description: 'Quản lý thông minh và tối ưu hiệu quả sử dụng cơ sở vật chất của doanh nghiệp với công nghệ IoT.',
		image: require('src/assets/hrm/w4.png')
	},
	{
		title: 'Truyền thông nội bộ 4.0',
		description: 'Đa dạng công cụ truyền thông và gắn kết nội bộ trong thời đại số. Gia tăng trải nghiệm khác biệt, thú vị cho nhân sự.',
		image: require('src/assets/hrm/w2.png')
	},
]

const WhyUseComponent: React.FC<{}> = () => {
	const { lg } = useBreakpoint()

	return (
		<section id="why-use" className="why-use">
			<div className="container">
				<div className="inner">
					<div className="title-block text-center">
						<h2 className="label cl1">Tại sao nên sử dụng?</h2>
						<span style={{ fontFamily: "helvetica neue" }} className="title">ACheckin</span>
						<span className="line" />
					</div>

					<div className="row row-cols-md-5">
						{WHY_USE.map((item, i) => {
							return (
								<div key={`whyuse${i}`} className={`${lg ? 'col' : 'col-6 mb-5'}`}>
									<div className="icon-item text-left">
										<img alt={`whyuse${i}`} src={item.image} height={100} />
									</div>
									<h3 className="title-item text-left">{item.title}</h3>
									<div className="content-item text-left">
										{item.description}
									</div>
								</div>
							)
						})}

					</div>

					{/* <div className="content-block grid text-left">
						{WHY_USE.map((item, i) => {
							return (
								<div key={`whyuse${i}`} className="item">
									<div className="icon-item text-left">
										<img alt={`whyuse${i}`} src={item.image} height={100} />
									</div>
									<h3 className="title-item text-left">{item.title}</h3>
									<div className="content-item text-left">
										{item.description}
									</div>
								</div>
							)
						})}
					</div> */}
				</div>
			</div>
		</section>
	);
};

export default WhyUseComponent;